.socialMediaItem {
  height: 30px;
  width: 30px;
  border: 1px solid var(--main800);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.socialMediaItem:hover {
  background-color: var(--main800);
}

.socialMediaIcon {
  font-size: 20px;
  transition-delay: 100ms;
}

.socialMediaItem:hover .socialMediaIcon {
  color: white;
}

.journalActions {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.journalActions a.mainBtn {
  text-decoration: none;
  border-radius: 4px;
  color: var(--main800);
  padding: 0.4rem 0.2rem;
  flex: 1;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
}

.journalActions a.mainBtn:hover {
  opacity: 0.9;
}

.journalActions a.btn {
  font-size: 0.9rem !important;
  padding: 0.4rem 0.2rem !important;
  line-height: 0.9rem;
}
