.mainNavWrapper {
  padding: 5px 2%;
}

#logo {
  margin-right: auto;
}

#logo {
  font-size: 2.2rem;
  min-width: 180px;
}

#logo img {
  width: 53px;
}

.logo {
  width: 80px;
}

.mainNav a {
  text-decoration: none;
  color: var(--mainNavHover);
}

.information {
  position: relative;
}

.information ul.informationUl {
  position: absolute;
  top: 100%;
  right: 0;
  display: none !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.information:hover ul {
  display: flex !important;
}

.mainNav {
  flex: 1;
}

.mainNav ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainNav ul li {
  position: relative;
  isolation: isolate;
}

.mainNav li.login {
  margin-left: auto;
}

.mainNav li:not(#logo) a {
  padding: 20px;
  transition: all 0.5s ease;
}

.mainNav li a:not(#logo)::after {
  display: none;
  transition: all 0.5s ease;
  opacity: 0;
  content: "";
  background-color: var(--mainNavHover);
  position: absolute;
  height: 100px;
  margin: auto;
  z-index: -1;
  inset: 0;
}

.mainNav li:not(#logo):hover a::after {
  display: block;
  opacity: 0.8;
}
.mainNav li:not(#logo):hover a {
  color: var(--main800);
}

.mainNav li:not(#logo) a:not(#logo).active {
  color: var(--main800) !important;
}

.mainNav li:not(#logo) a.active::after {
  display: block !important;
  opacity: 1 !important;
}

button.changeLanguageBtn:disabled {
  opacity: 0.5;
  background-color: gray;
}

.mainNav select {
  background-color: var(--main800);
  color: white;
  border: 1px solid;
  padding-right: 0.3rem !important;
}
