.journalMenu {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.journalMenuContainer,
.conferencesMenuContainer {
  flex: 1;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  min-width: 190px;
}

.journalMenuContainer nav a,
.conferencesMenuContainer nav a {
  color: var(--main800);
  border-radius: 4px;
  padding: 0.3rem 0.2rem;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.1s ease-in;
  font-size: 0.8rem;
}

.journalMenuContainer nav a:hover,
.conferencesMenuContainer nav a:hover {
  background-color: var(--main05);
  color: white;
}

.journalMenuContainer nav a.active,
.conferencesMenuContainer nav a.active {
  background-color: var(--main800);
  color: white;
}

.journalMenuContainer nav a.active::before,
.conferencesMenuContainer nav a.active::before {
  transition: all 0.1s ease-in;
  background-color: white;
}
.journalMenuContainer span,
.conferencesMenuContainer span {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 10px;
}

.journalMenuContainer nav a::before,
.conferencesMenuContainer nav a::before {
  content: "";
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: var(--main05);
}

.journalMenuContainer nav a:hover,
.conferencesMenuContainer nav a:hover {
  background-color: var(--main05);
  color: white;
}
