.articlesList {
  flex: 4;
  display: flex;
  flex-direction: column;
}

.articlesItem {
  margin-top: 10px;
}

.journalDescription {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.contentfulDiv.journalDescription.hiddenBanner {
  background-color: var(--main800);
  color: white;
  background-color: var(--main800);
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.contentfulDiv.journalDescription.hiddenBanner .descriptionContent * {
  color: white !important;
}
