.sidebarRight {
  flex: 1;
  min-width: 225px;
}

.sidebarRight :is(h2, p, input) {
  margin-bottom: 10px;
}
.sidebarRight a {
  text-decoration: none;
}

.sidebarRight span {
  color: #a1a1a1;
  font-size: 0.8rem;
}

.sidebarRight a p {
  text-decoration: none;
  color: var(--textColor);
  line-height: 1rem;
  font-weight: bold;
  font-size: 0.8rem;
}
