html body * {
  font-family: neue, Helvetica, Roboto, Arial, sans-serif !important;
}
:root {
  font-size: 16px;
}
p {
  margin-bottom: 5px;
}


.PWRI #logo a {
  font-size: 18px;
}
.PWRI footer .logo  {
  font-size: 20px !important;
  white-space: normal !important;
}
.PWRI .mainNavWrapper {
  min-height: 62.8px;
}

.PWRI footer .navHeader {
  flex: none;
}

input,
select {
  outline: none;
  font-size: 0.93rem;
  padding: 5px 5px;
  border-radius: 4px;
  color: var(--textColor);
  border: 1px solid var(--main05);
}

nav.mainNav * {
  font-family: "Nunito", sans-serif !important;
  font-weight: 500;
}

.mainSearchFrom span {
  font-family: "Nunito", sans-serif !important;
}

html body :is(input, select, button) {
  font-family: "Nunito", sans-serif !important;
}

.btn-outline-success {
  --bs-btn-color: var(--main800);
  --bs-btn-border-color: var(--main800);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--main800);
  --bs-btn-hover-border-color: var(--main800);
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--main800);
  --bs-btn-active-border-color: var(--main800);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--main05);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--main05);
  --bs-gradient: none;
}
input:focus,
select:focus {
  border: 1px solid var(--main800);
}

.innerDiv {
  padding: 10px 2rem !important;
}

input::placeholder {
  color: var(--textMuted);
}

.articleItem p {
  margin-bottom: 0;
}

.articleItem:hover {
  cursor: pointer;
  background-color: rgb(1 50 75 / 4%);
}

.mainBtn {
  background-color: var(--main800);
  color: white;
  transition: all 0.1s ease;
  transform: scale(1);
  color: white;
}

.mainBtn:hover {
  background-color: var(--main800);
  color: white;
  opacity: 0.8;
}

.marginRightAuto {
  margin-right: auto;
}

.mainWrapper {
  width: 80%;
  margin: 0 auto;
}

body {
  background-color: var(--main01);
}

.main800Background {
  background-color: var(--main800);
}

.main05Background {
  background-color: var(--main05);
}

main {
  background-color: var(--grayish);
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 256px);
}

article a {
  text-decoration: none;
}

main > div:first-of-type {
  gap: 20px;
}

input[type="radio"] {
  min-width: auto !important;
}

.contentfulDiv {
  background-color: white;
  padding: 1.5rem 10px;
}
.contentfulDiv h2 {
  font-size: 1.3rem;
  margin-bottom: 6px;
  font-weight: bold;
  margin: 0 auto;
  margin-bottom: 10px;
}
.contentfulDiv p {
  font-weight: 400;
  font-size: 0.7rem;
  text-align: center;
  margin: 0 auto;
}

.contentfulDiv *:not(h2) {
  font-size: 0.85rem !important;
}

.contentfulDiv > div * {
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;

  margin: 0 auto;
}

.contentfulDiv > div {
  width: 90%;
  margin: 0 auto;
}

.positionSticky {
  position: sticky;
  top: 20px;
}

.articleItem {
  color: var(--textColor);
  padding-bottom: 10px;
}

.articleItem:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.articleItem h2 {
  font-weight: bold;
  margin-bottom: 10px;
  font-family: Arial;
  font-size: 1.2rem;
  line-height: 22px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  color: var(--textColor);
}

.articleItem span.publication {
  font-weight: 400;
  margin-top: 2px;
  display: block;
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: var(--textMuted);
}

.articleItem .authors {
  font-size: 0.8rem;
  font-weight: bold;
}

.articleItem .abstract {
  font-size: 0.8rem;
  line-height: 0.9rem;
}

.articleItem .proprietary {
  font-size: 0.8rem;
  margin-top: 10px;
}

.articleItem .proprietary span {
  font-weight: bold;
}

.articleItem .abstract span {
  font-weight: bold;
}

.navHeader {
  flex: 1;
  overflow: hidden;
}

.card {
  border-radius: 5px;
  padding: 0.7rem;
  color: var(--textColor);
  font-size: 0.8rem;
  line-height: 1rem;
}

.card h2 {
  font-size: 1.2rem;
}

footer {
  background-color: var(--main800);
}

.boardHeader {
  background-color: var(--main05);
  padding: 10px;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.issueLink,
.newsLink {
  display: block;
  padding: 5px 10px;
  border-radius: 3px;
}

.issueLink h2,
.newsLink h2 {
  font-size: 0.83rem;
  font-weight: bold;
  margin-bottom: 5px !important;
  word-break: break-word;
}

.newsLink h2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.issueLink p,
.newsLink p {
  font-weight: 200 !important;
  margin: 0px;
  font-size: 0.75rem;
  margin-bottom: 5px !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.issueWrapper {
  margin-bottom: 10px;
}

.issueWrapper:not(:last-child),
.newsWrapper:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.topicContainer:not(:first-child) {
  margin-top: 20px;
}
.topicContainer ul li {
  color: gray;
}

.topicContainer:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.issueWrapper p,
.newsWrapper p {
  margin-bottom: 5px;
}

.issueLink:not(:last-child),
.newsLink:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.issueLink:not(:first-of-type),
.newsLink:not(:first-of-type) {
  margin-top: 10px;
}

.issueLink:hover,
.conferenceLink:hover,
.newsLink:hover {
  background-color: var(--main01);
}

.sidebarRight .conferenceLink {
  text-decoration: underline;
  margin-bottom: 5px;
  padding: 5px;
}

.boardContainer {
  min-height: 500px;
}

.boardMemberContainer :is(p, h2) {
  margin: 0;
}
.boardMemberContainer {
  padding: 10px;
  gap: 20px;
}

.boardMemberContainer p span {
  font-weight: bold;
  font-style: normal;
}

.boardMemberContainer > div > p:first-of-type {
  margin-top: 2px;
}

.boardMemberContainer:not(:first-child) {
  margin-top: 10px;
}

.boardMemberContainer:not(:last-child) {
  border-bottom: 1px solid lightgray;
}
.boardMemberContainer h2 {
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--textColor);
}
.boardMemberContainer .header {
  gap: 10px;
}

nav ul {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

.boardMemberContainer span {
  font-style: italic;
  margin-bottom: 5px;
}
.boardMemberContainer img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.boardMemberContainer .contact {
  margin-top: 2px;
  gap: 3px;
}

.boardMemberContainer .contact span {
  font-weight: bold;
  font-style: normal;
}

.boardMemberContainer .biography {
  margin-top: 2px;
}

.boardMemberContainer a {
  color: var(--textColor);
}

a {
  color: var(--textColor);
}

.hamburgerMenu {
  display: none;
  background-color: var(--main800);
  border-radius: 5px;
  padding: 10px;
  width: 40px;
  outline-color: transparent;
  border: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.hamburgerMenu rect {
  fill: white;
  transition: all 0.3s ease;
  transition-delay: 0.2s;
  transform-origin: center;
}
/* .mainInputWrapper {
  width: calc(100% - 166px - 269px);
} */

.hamburgerMenu.active .line {
  transition: y 300ms ease-in, rotate 300ms ease-in 300ms, opacity 0ms 300ms;
}

.hamburgerMenu.active :is(.top, .bottom) {
  y: 45;
}

.hamburgerMenu.active .top {
  rotate: 45deg;
}

.hamburgerMenu.active .middle {
  opacity: 0;
}

.hamburgerMenu.active .bottom {
  rotate: -45deg;
}

.emptyResponse i {
  margin-top: 20px;
  font-size: 3rem;
  color: var(--main800);
}

.emptyResponse p {
  margin-top: 20px;
  font-size: 1.2rem;
}

.emptyResponse {
  height: 100%;
}

.errorPage {
  text-align: center;
}

.errorPage h1 {
  font-size: 3rem;
  color: var(--main800);
  margin: 0;
  font-weight: 600;
}

.errorPage h5 {
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  color: var(--main800);
  margin-bottom: 22px;
}

.errorPage p {
  font-size: 0.9rem;
  color: black;
}

.errorPage button {
  background-color: var(--main800);
  color: white;
  transition: all 0.1s ease;
  transform: scale(1);
  color: white;
  min-width: 140px;
  margin: 10px 0;
}

.errorPage button:hover {
  background-color: var(--main800);
  color: white;
  opacity: 0.8;
}

@media screen and (max-width: 1250px) {
  .mainContainer {
    flex-wrap: wrap;
  }

  footer .mainNavWrapper {
    display: none !important;
  }

  .mainWrapper {
    width: 90%;
  }
  main .hamburgerMenu {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 99999;
    width: 100%;
  }
  .mainContainer .sidebarLeftContainer {
    z-index: 9999;
    top: 5px;
    max-height: 95vh;
    overflow: auto;
    transition: all 0.5s ease;
    border-color: 2px solid var(--main800);
    width: 100%;
  }

  .mainContainer .sidebarLeftContainer:has(.sidebarLeft.showMenu) {
    background-color: var(--main800);
    padding: 15px;
    border-radius: 5px;
  }

  .mainContainer .sidebarLeft {
    margin-top: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: none;
  }

  .mainContainer .sidebarLeft.showMenu {
    display: block;
  }

  .mainContainer .sidebarRight {
    width: 100%;
    margin-bottom: calc(20px + 0.5rem);
    flex: unset;
  }
  .mainContainer .sidebarRight .subscibeSidebar {
    display: none;
  }
  .backLink {
    display: none;
  }
}

@media screen and (max-width: 1150px) {
  .mainInputWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 630px) {
  .inputContainer {
    flex-wrap: wrap;
  }
  .inputContainer :is(input, select) {
    min-width: calc(50% - 20px);
  }
  .mainWrapper {
    width: 100%;
  }
  nav .hamburgerMenu {
    display: flex;
  }
}

@media screen and (max-width: 770px) {
  nav .hamburgerMenu {
    display: flex;
  }

  .mainNav:has(ul.active) .hamburgerMenu {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 99999999999999999;
  }

  body .mainNav ul {
    position: fixed;
    top: 0;
    right: 300px;
    bottom: 0;
    flex-direction: column;
    gap: 10px;
    display: none !important;
    background-color: var(--main800);
    transition: right 0.3s ease;
    z-index: 9999999;
    width: 300px;
    border-left: 1px solid white;
  }

  body .mainNav ul.active {
    display: flex !important;
    right: 0px;
  }

  body .mainNav ul.active ::after {
    height: auto !important;
  }

  body .mainNav ul.active li {
    margin: 0px !important;
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
}

.animate-issue {
  animation-name: scale-in;
  animation-duration: 1s;
}

@keyframes scale-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 630px) {
  .inputContainer {
    flex-wrap: wrap;
  }
  .inputContainer :is(input, select) {
    min-width: calc(50% - 20px);
  }
  .mainWrapper {
    width: 100%;
  }
  nav .hamburgerMenu {
    display: flex;
  }
}

@media screen and (max-width: 630px) {
  .boardMemberContainer {
    flex-wrap: wrap;
  }
  .boardMemberContainer img {
    order: -1;
  }
}

.authorInfoWrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.authorInfoWrapper span {
  min-width: 170px;
  max-width: 170px;
  font-size: 0.9rem;
}

.authors div:has(> div.authorInfoWrapper) {
  flex: 1;
}

.authorInfoWrapper img,
.authorInfoWrapper svg {
  width: 27px;
  height: 27px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid lightgray;
}
.authorInfoWrapper svg {
  padding: 5px;
  border: 1px solid gray;
}

.authorInfoWrapper > i {
  font-size: 15px;
}

.articleWrapper .previewBtn {
  background-color: var(--main01);
  border-radius: 0;
  font-weight: 600;
  font-size: 13px;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.articleWrapper .previewBtn > svg {
  margin: 0 5px;
  font-size: 15px;
}

.articleWrapper .previewBtn:hover,
.articleWrapper .previewBtn:active {
  opacity: 0.8;
}

.chartWrapper > p:first-child {
  font-size: 13px;
  font-weight: 600;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 0;
}

.chartWrapper > p:last-child {
  text-align: end;
  width: 95%;
}

.newsWrapper {
  cursor: pointer;
  margin-bottom: 10px;
}

.newsWrapper:hover {
  background-color: var(--main01);
}

.newsWrapper > span.newsLimitedContent {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 8px;
}

.newsWrapper span.newsTitle {
  margin-top: 0;
  line-height: 1.2;
  color: var(--bs-heading-color, inherit);
  display: block;
  font-size: 1.5em;
  margin-block-end: 0.45em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
