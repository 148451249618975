footer {
  color: white;
  padding: 20px var(--paddingLeftRight);
}

footer .logo {
  font-size: 2.3rem;
  white-space: nowrap;
  width: auto;
}

footer img {
  width: 53px;
}
footer button {
  display: block;
  color: white !important;
  margin-left: 5px;
}
footer p {
  margin-bottom: 10px;
}

footer button:hover {
  background-color: var(--mainLighterAccent) !important;
}
footer .footerSubsricbeBtn:hover {
  background-color: white !important;
  color: var(--main800) !important;
}

.copyright {
  background-color: var(--footerBackground);
  color: white;
  font-weight: 100;
  padding: 5px var(--paddingLeftRight);
}
