.conferencesMenu p {
  color: var(--main800);
  border-radius: 4px;
  padding: 0.3rem 0.2rem;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.1s ease-in;
  font-size: 0.8rem;
}

.assessment-report a,
.conference-program a,
.abstract-submission-form a,
.paper-submission a,
.sponsorship a,
.venue a,
.registration a,
.abstract-submission a,
.book-abstracts a {
  color: #2d7d7d;
  outline: none;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  font-size: 14px;
  text-decoration: none;
}

.assessment-report a:hover,
.conference-program a:hover,
.abstract-submission-form a:hover,
.paper-submission a:hover,
.sponsorship a:hover,
.venue a:hover,
.registration a:hover,
.abstract-submission a:hover,
.book-abstracts a:hover {
  color: #f06422;
  text-decoration: underline;
}

.assessment-report .document-listing {
  margin-top: 20px;
}

.assessment-report .document-listing a {
  padding: 10px 50px 10px 10px;
  display: block;
  color: #2b2b2b;
  font-size: 18px;
  line-height: 26px;
  font-family: "source_sans_proregular";
  background: url("../../Assets/Images//pdf-icon.png") top 50% right 5px
    no-repeat;
}

.assessment-report .report {
  margin-bottom: 6px;
  border-bottom: 1px solid #707070;
}

.document-listing a:hover {
  text-decoration: none;
  background-color: rgb(211 211 211 / 26%);
}

.boardHeader.backLink {
  background: transparent;
  color: var(--main800);
  cursor: pointer;
}

.boardHeader.backLink:hover {
  opacity: 0.8;
}

.program td {
  padding: 10px;
  vertical-align: top;
}

.program td:first-child {
  min-width: 105px;
}

.program tr:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.document--listing {
  padding: 1px 22px 1px 15px;
}

.document--listing .repeat {
  margin-bottom: 6px;
  border-bottom: 1px solid #707070;
}

.document--listing a {
  padding: 13px 50px 13px 10px;
  display: block;
  color: #2b2b2b;
  font-size: 18px;
  line-height: 26px;
  font-family: "source_sans_proregular";
  background: url(/src/Assets//Images//pdf-icon.png) top 50% right 5px no-repeat;
  text-decoration: none;
}

.document--listing a:hover {
  background-color: rgb(211 211 211 / 26%);
}

.abstract-submission-form {
  margin: 12px 0;
}

.committees-conference table th,
td {
  padding: 0.4em;
  vertical-align: top;
}

.key-speakers p,
.chair-of-conference p,
.technical-secretariat p {
  padding-bottom: 10px;
}

.organizing-partners img {
  object-fit: contain;
}

.redColor {
  color: #ff0000;
}

.headerColor {
  color: #333399;
}

.textJustify {
  text-align: justify;
}
