.showcaseContainer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  flex: 1;
  position: relative;
}

.showcaseContainer h2 {
  font-size: 1rem;
}

.showcaseDetails {
  position: absolute;
  bottom: 28px;
  right: 0;
  color: white;
  min-height: 30%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 100%;
  background-color: var(--main05);
}

.carousel .thumbs-wrapper.axis-vertical,
.carousel .control-dots {
  display: none;
}

.carousel li.slide img {
  height: 200px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel li.slide .showcaseDetails h2 {
  font-size: 1rem;
}

article.shimmer-article {
  background: white;
  width: 100%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

article.shimmer-article .line {
  width: 100%;
  height: 20px;
  background: #e4e3e3;
  margin: 20px 0;
  border-radius: 5px;
}

article.shimmer-article .shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
