.mainSearchFrom {
  padding: 10px 0.5rem;
  background-color: white;
  flex-wrap: wrap;
  gap: 20px;
}

.mainSearchFrom span {
  text-align: center;
  font-size: 1rem;
  /* margin-right: auto; */
  gap: 20px;
}

.inputContainer {
  gap: 20px;
  justify-content: space-between;
}

.inputContainer > :not(.inputContainer) {
  width: 100%;
}

.inputContainer > * {
  max-width: calc(25% - 20px);
}

form.mainSearchFrom input,
form.mainSearchFrom select,
form.mainSearchFrom input[type="date"] {
  width: 100%;
  max-width: 100%;
}

.mainJournalFilterButtons {
  gap: 10px;
  /* margin-left: auto; */
}

.emptyResult {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyResultIcon {
  font-size: 40px;
  color: var(--main800);
  margin-bottom: 20px;
}

.emptyResult p {
  font-size: 1.2rem;
  margin-bottom: 0;
  text-align: center;
  line-height: 1.5;
}

.advancedSearch {
  display: none;
}

.showSearch {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mainSearchFrom > div.searchDiv {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 4;
  /* padding: 0px 273px 0 213px; */
}

input[type="date"]::before {
  content: attr(data-placeholder);
  width: 100%;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  display: none;
}

@media screen and (max-width: 1555px) {
  .mainSearchFrom > div.searchDiv {
    padding: 0;
  }
  .mainSearchFrom .journalMenuContainer,
  .mainSearchFrom .positionSticky.sidebarRight {
    display: none;
  }
}

@media screen and (max-width: 765px) {
  .searchDiv {
    flex-direction: column;
  }
}
